<template>
  <!-- 大赛培训 -->
    <div>
      <Footer ></Footer>
        <div class="content">
          <div class="imgbox">
            <!-- 背景图片 -->
            <img class="img" src="./Train_img/background.png" alt="">
            <img class="img_svg" src="./Train_img/SVG.png" alt="">
            <img class="img_one" src="./Train_img/img_one.png" alt="">
            <img class="img_two"  src="./Train_img/img_two.png" alt="">
            <img class="img_three"  src="./Train_img/img_three.png" alt="">


            <img class="img_dot" src="./Train_img/dot.png" alt="">
            <img class="img_dotp" src="./Train_img/dot.png" alt="">
            <img class="img_dotpow" src="./Train_img/dot.png" alt="">

            <!-- 三个圈 -->
            <div class="circle"></div>
            <div class="circle_one"></div>
            <div class="circle_two"></div>
            <div class="circle_three"></div>
            <div class="circle_four"></div>

          </div>
          <!-- 大赛官方线上培训平台学唐Online -->
          <div class="school">
              <span class="school_title">
                  大赛官方线上培训平台
              </span>
              <span class="school_title2">
                  学唐Online
              </span>
              <p class="school_text">5G/AI/车联网</p>
              <p>课程体系/线上考试/社区论坛 </p>
              <p>大唐杯大赛内容在线学习</p>
              <p>大赛操作指南/直播回放</p>
              <el-button class="but" type="warning" @click="register">登录学唐Online</el-button>
          </div>
          <!-- 虚拟仿真练习与实操实训平台 -->
          <div class="practice">
              <span class="practice_title">虚拟仿真练习与实操实训平台</span>
              <p class="practice_text">真实设备模拟</p>
              <p>实操场景训练 </p>
              <p>虚实结合、无限可能</p>
              <el-button class="but" type="warning" @click="platform">登录虚拟仿真平台</el-button>
          </div>

          <!-- 工程概论在线课程平台 -->
          <div class="course">
              <span class="school_title">
                工程概论在线课程平台
              </span>
              <p class="school_text">掌握工程逻辑，形成工程思维</p>
              <p>业界优质课程，经典工程案例</p>
              <p>多种培训方案，名师线上指导</p>
              <el-button class="but" type="warning" >工程概论在线课程平台</el-button>
          </div>

        </div>
      <Bottom></Bottom>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Footer from '@/components/Footer/Footer.vue'
  import Bottom from '@/components/Bottom/Bottom.vue'
  import $ from "jquery";
  
  export default {
    name: 'Home',
    components: {
      Footer,
      Bottom,
    },
    data(){
      return {
        lock:0,
      }
    },
    methods:{
      // 学堂跳转登录
      register(){
        window.open('http://dtmobile.yunxuetang.cn/login.htm', '_blank')
      },
      platform(){
        window.open('https://www.dtxiaotangren.com', '_blank')
      }
      
    },
  }
  </script>
  
  <style scoped lang="scss">
  @import './Train.scss';
  </style>